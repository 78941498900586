import request from '@/utils/request';
import { AxiosResponse } from 'axios';

interface HistoryDataResponse {
  data: {
    message: string,
    status: number,
    data: any
  };
}

interface UserInfoResponse {
  data: {
    data: any
  };
}

export const submitIdCardChangeRequest = async (reason: string) => {
  const response: AxiosResponse<NcuosResponse> = await request.post(
    'https://v2.ncuos.com/api/affair/card',
    { apply_reason: reason },
  );
  return response;
};

export const fetchIdCardChangeHisory = async () => {
  const response: AxiosResponse<HistoryDataResponse> = await request.get(
    'https://v2.ncuos.com/api/affair/card',
  );
  return response;
};

export const fetchStudentInfo = async () => {
  const queryContent = `
  {
    mine {
        studentID
        name
        gender
        admissionTime
        idNumber
        birthplace
        nativePlace
        ethnicGroup
        phone
        currentStatus {
          major
          department
        }
    }
  }`;
  const response: AxiosResponse<UserInfoResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: queryContent,
    },
  );
  return response;
};
