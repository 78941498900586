export const timeMap = [
  {
    time: '07:50',
    hightLight: true,
  },
  {
    time: '08:40',
    hightLight: false,
  },
  {
    time: '09:45',
    hightLight: false,
  },
  {
    time: '10:35',
    hightLight: false,
  },
  {
    time: '11:25',
    hightLight: false,
  },
  {
    time: '13:50',
    hightLight: true,
  },
  {
    time: '14:40',
    hightLight: false,
  },
  {
    time: '15:45',
    hightLight: false,
  },
  {
    time: '16:35',
    hightLight: false,
  },
  {
    time: '17:25',
    hightLight: false,
  },
  {
    time: '19:00',
    hightLight: true,
  },
  {
    time: '19:50',
    hightLight: false,
  },
  {
    time: '20:40',
    hightLight: false,
  },
];

export const parseTime = (time: string | number, cFormat: string) => {
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else if (`${time}`.length === 10) {
    date = new Date(typeof time === 'string' ? parseInt(time, 10) * 1000 : time * 1000);
  } else {
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value: string = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && Number(value) < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
  return timeStr;
};
