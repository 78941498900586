import { Effect } from 'dva';
import { Reducer } from 'redux';
import { notification } from 'antd';
import { cloneDeep } from 'lodash';
import { fetchStudentId, StudentIdResponse } from '@/services/student_affairs/print_proof';
import {
  updatePhoto, fetchRecords, RecordsResponse, UpdatePhotoResponse,
} from '@/services/student_affairs/update_photo';
import { fetchPhoto, PhotoResponse } from '@/services/qiniu';

export type IPhotoRecodesState = Partial<RecordsResponse['data']>;

export interface IPhotoModelType {
  namespace: string;
  state: IPhotoRecodesState;
  effects: {
    requestRecords: Effect;
    requestPhoto: Effect;
    postPhoto: Effect;
  }
  reducers: {
    updateData: Reducer<IPhotoRecodesState>;
    updatePhoto: Reducer<IPhotoRecodesState>;
  }
}

const PhotoModel: IPhotoModelType = {
  namespace: 'photo',

  state: {
    pic: '',
    ischeck: true,
    ispass: true,
  },

  effects: {
    * requestRecords(_, { call, put }) {
      yield put({
        type: 'requestPhoto',
      });
      const res: RecordsResponse = yield call(fetchRecords);
      const { data = {} } = res;
      yield put({
        type: 'updateData',
        payload: {
          ...data,
        },
      });
    },
    * requestPhoto(_, { call, put }) {
      try {
        const idRes: StudentIdResponse = yield call(fetchStudentId);
        const { data: { mine: { studentID } } } = idRes;
        if (studentID) {
          const photoRes: PhotoResponse = yield call(fetchPhoto, studentID);
          const { data: imgUrl } = photoRes;
          if (imgUrl?.length > 0) {
            yield put({
              type: 'updatePhoto',
              payload: imgUrl,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    * postPhoto({ base64data }, { call }) {
      const response: UpdatePhotoResponse = yield call(updatePhoto, base64data);

      if (response.status === 0) {
        notification.success({ message: '上传成功' });
      } else {
        notification.error({ message: '上传失败' });
      }
    },
  },

  reducers: {
    updateData(state, { payload }) {
      return {
        ...state,
        ...cloneDeep(payload),
      };
    },
    updatePhoto(state, { payload }) {
      return {
        ...state,
        pic: payload,
      };
    },
  },
};

export default PhotoModel;
