import request from '@/utils/request';
import { AxiosResponse } from 'axios';

export interface ClassMember {
  studentID: string;
  name: string;
  phone: string;
  qq: string;
  dormitory: string;
}

export interface CurrentStatus {
  className: string;
  major: string;
  department: string;
}

export interface CurrentTermResponse {
  data: {
    termStatus: {
      currentTerm: string
    }
  }
}
export interface QueryResponse {
  data: {
    mine: {
      currentStatus: CurrentStatus;
      classMembers: ClassMember[];
    }
  }
}

export const queryCurrentTerm = async () => {
  const queryContent = `
  {
    termStatus {
      currentTerm
    }
  }
  `;
  const res: AxiosResponse<CurrentTermResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: queryContent,
    },
  );

  return res.data;
};

export const queryData = async (term: string) => {
  const graphqlQueryContent = `
  {
    mine {
      currentStatus {
        className
        major
        department
      }
      classMembers(term: "${term}") {
        studentID
        name
        phone
        qq
        dormitory
      }
    }
  }`;
  const res: AxiosResponse<QueryResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: graphqlQueryContent,
    },
  );

  return res.data;
};
