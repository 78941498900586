// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelFeedback0 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/feedback.ts';
import ModelGlobal1 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/global.ts';
import ModelLogin2 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/login.ts';
import ModelNotice3 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/notice.ts';
import ModelSchedule4 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/schedule.ts';
import ModelSettings5 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/settings.ts';
import ModelComprehensiveQuality6 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/comprehensive_quality.ts';
import ModelExcellentStudents7 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/excellent_students.ts';
import ModelPrintProof8 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/print_proof.ts';
import ModelSafetyEducation9 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/safety_education.ts';
import ModelSparkApply10 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/spark_apply.ts';
import ModelStudentIdcard11 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/student_idcard.ts';
import ModelUpdatePhoto12 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/update_photo.ts';
import ModelVacationRegister13 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/student_affairs/vacation_register.ts';
import ModelUser14 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/user.ts';
import ModelClassContacts15 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/usually_query/class_contacts.ts';
import ModelDisciplinePunish16 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/usually_query/discipline_punish.ts';
import ModelGradesQuery17 from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/models/usually_query/grades_query.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'feedback', ...ModelFeedback0 });
app.model({ namespace: 'global', ...ModelGlobal1 });
app.model({ namespace: 'login', ...ModelLogin2 });
app.model({ namespace: 'notice', ...ModelNotice3 });
app.model({ namespace: 'schedule', ...ModelSchedule4 });
app.model({ namespace: 'settings', ...ModelSettings5 });
app.model({ namespace: 'comprehensive_quality', ...ModelComprehensiveQuality6 });
app.model({ namespace: 'excellent_students', ...ModelExcellentStudents7 });
app.model({ namespace: 'print_proof', ...ModelPrintProof8 });
app.model({ namespace: 'safety_education', ...ModelSafetyEducation9 });
app.model({ namespace: 'spark_apply', ...ModelSparkApply10 });
app.model({ namespace: 'student_idcard', ...ModelStudentIdcard11 });
app.model({ namespace: 'update_photo', ...ModelUpdatePhoto12 });
app.model({ namespace: 'vacation_register', ...ModelVacationRegister13 });
app.model({ namespace: 'user', ...ModelUser14 });
app.model({ namespace: 'class_contacts', ...ModelClassContacts15 });
app.model({ namespace: 'discipline_punish', ...ModelDisciplinePunish16 });
app.model({ namespace: 'grades_query', ...ModelGradesQuery17 });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (typeof window !== 'undefined') {
      _onCreate();
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
