// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/jungbey/Documents/work/react_projects/ncuos-fe/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "routes": [
      {
        "name": "登录",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/dashboard",
        "name": "工作台",
        "icon": "dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__dashboard' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/home/dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/usually_query",
        "name": "常用查询",
        "icon": "file-search",
        "routes": [
          {
            "path": "/usually_query/grades_query",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__usually_query__grades_query' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/usually_query/grades_query'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/usually_query/class_contacts",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__usually_query__class_contacts' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/usually_query/class_contacts'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/usually_query/discipline_punish",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__usually_query__discipline_punish' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/usually_query/discipline_punish'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/usually_query/excellent_students",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__usually_query__excellent_students' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/usually_query/excellent_students'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/student_affairs",
        "name": "学生事务",
        "icon": "solution",
        "routes": [
          {
            "path": "/student_affairs/counsellor_comment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__counsellor_comment' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/counsellor_comment'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/print_proof",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__print_proof' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/print_proof'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/safety_education",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__safety_education' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/safety_education'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/student_affairs/safety_education/course_learning",
                "name": "课程学习",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__safety_education__course_learning' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/safety_education/course_learning'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/student_affairs/comprehensive_quality",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__comprehensive_quality' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/comprehensive_quality'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/student_idcard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__student_idcard' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/student_idcard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/vacation_register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__vacation_register' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/vacation_register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/update_photo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__update_photo' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/update_photo'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/student_affairs/spark_apply",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__student_affairs__spark_apply' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/student_affairs/spark_apply'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/admin",
        "name": "管理模块",
        "icon": "control",
        "routes": [
          {
            "path": "/admin/safety_education_setting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__safety_education_setting' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/admin/safety_education_setting'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/admin/safety_education_management",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__safety_education_management' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/admin/safety_education_management'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/settings",
        "name": "账户设置",
        "icon": "setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/feedback",
        "name": "用户反馈",
        "icon": "message",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__feedback' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/feedback'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/jungbey/Documents/work/react_projects/ncuos-fe/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
