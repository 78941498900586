import request from '@/utils/request';

export interface TermsRes {
  data: string[],
  message: string;
  status: number;
}

export async function fetchQuality(params: string) {
  return request.post('https://v2.ncuos.com/api/query/comprehensive/query', {
    params: {
      semester: params,
    },
  });
}

export async function fetchTerms() {
  return request.get('https://v2.ncuos.com/api/account/user/term');
}
