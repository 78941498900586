import request from '@/utils/request';
import { AxiosResponse } from 'axios';
import { isMobile } from 'react-device-detect';

export interface LoginResData {
  token: string
  user: {
    role: string
    username: string
  }
}
export interface LoginRes extends NcuosResponse {
  data: LoginResData
}

export interface LoginDto {
  username: string;
  password: string;
}

export async function login(data: LoginDto) {
  const response: AxiosResponse<LoginRes> = await request.post('https://v2.ncuos.com/api/account/user/login', {
    ...data,
    device_type: isMobile ? 'Mobile' : 'PC',
  });
  return response.data;
}
