import { history } from 'umi';
import { Effect } from 'dva';
import { message, notification } from 'antd';
import { Reducer } from 'redux';
import { logout, fetchAccessLogs, fetchPhoneVerifyCode } from '@/services/settings';

import themeColorClient from '@/components/SettingDrawer/themeColorClient';
import { changePassword, changePhone } from '../services/settings';
import defaultSettings, { DefaultSettings } from '../../config/defaultSettings';

export interface HistoryItem{
  ipAddress?: string;
  deviceType?: string;
  accessTime?: string;
}

export interface ISettingsState extends DefaultSettings{
  loginHistory: HistoryItem[];
  hasSentVerifyCode: boolean;
}

export interface ISettingsModelType {
  namespace: string;
  state: ISettingsState;
  effects: {
    requestPhoneVerifyCode: Effect;
    requestAccessLogs: Effect;
    requestLogout: Effect;
    restoreVerifyCodeStatus: Effect;
    requestChangePhone: Effect;
    requestChangePassword: Effect;
  },
  reducers: {
    updateHasSentVerifyCode: Reducer<ISettingsState>;
    updateHistory: Reducer<ISettingsState>;
    getSetting: Reducer<ISettingsState>;
    changeSetting: Reducer<ISettingsState>;
  };
}

const initData = {
  ...defaultSettings,
  loginHistory: [],
  hasSentVerifyCode: false,
};

const updateTheme = (newPrimaryColor?: string) => {
  if (newPrimaryColor) {
    const timeOut = 0;
    const hideMessage = message.loading('正在切换主题！', timeOut);
    themeColorClient.changeColor(newPrimaryColor).finally(() => hideMessage());
  }
};

const updateColorWeak: (colorWeak: boolean) => void = (colorWeak) => {
  const root = document.getElementById('root');
  if (root) {
    root.className = colorWeak ? 'colorWeak' : '';
  }
};

const Model: ISettingsModelType = {
  namespace: 'settings',
  state: initData,
  effects: {
    * requestPhoneVerifyCode(_, { call, put }) {
      try {
        const response = yield call(fetchPhoneVerifyCode);
        if (response.status === 200) {
          yield put({ type: 'updateHasSentVerifyCode', payload: true });
          message.success('验证码已发送，请注意查收');
        } else {
          message.error('发送失败，请稍后再试');
        }
      } catch (err) {
        message.error('发送失败，请稍后再试');
      }
    },
    * requestAccessLogs(_, { call, put }) {
      const response = yield call(fetchAccessLogs);
      if (response.status === 200) {
        yield put({ type: 'updateHistory', payload: response.data.data });
      } else {
        notification.error({ message: '拉取登录记录失败' });
      }
    },
    * requestLogout(_, { call }) {
      const response = yield call(logout);
      if (response.status === 200) {
        notification.success({ message: '注销成功' });
        yield localStorage.removeItem('token');
        history.push('/user/login');
      } else {
        notification.error({ message: '注销失败' });
      }
    },
    * restoreVerifyCodeStatus(_, { put }) {
      yield put({ type: 'updateHasSentVerifyCode', payload: false });
    },
    * requestChangePhone({ payload }, { call }) {
      const { verifyCode, newPhone } = payload;
      try {
        yield call(changePhone, verifyCode, newPhone);
        message.success('修改成功');
      } catch (error) {
        switch (error.response.status) {
          case 403:
            message.error('验证码错误或已过期, 请检查');
            break;
          default:
            message.error('请求错误，请稍后重试');
        }
      }
    },
    * requestChangePassword({ payload }, { call }) {
      const { oldPassword, newPassword } = payload;

      try {
        yield call(changePassword, oldPassword, newPassword);
        message.success('修改成功，请使用新密码登录');
        yield localStorage.removeItem('token');
        history.push('/user/login');
      } catch (error) {
        message.error('请检查原密码是否正确');
      }
    },
  },
  reducers: {
    updateHasSentVerifyCode(state = initData, { payload }) {
      return { ...state, hasSentVerifyCode: payload };
    },
    updateHistory(state = initData, { payload }) {
      return { ...state, loginHistory: payload.AccessLogs.accessLogs };
    },
    getSetting(state = initData) {
      const setting: Partial<DefaultSettings> = {};
      const urlParams = new URL(window.location.href);
      Object.keys(state).forEach((key) => {
        if (urlParams.searchParams.has(key)) {
          const value = urlParams.searchParams.get(key);
          setting[key] = value === '1' ? true : value;
        }
      });
      const { primaryColor, colorWeak } = setting;

      if (primaryColor && state.primaryColor !== primaryColor) {
        updateTheme(primaryColor);
      }
      updateColorWeak(!!colorWeak);
      return {
        ...state,
        ...setting,
      };
    },
    changeSetting(state = initData, { payload }) {
      const urlParams = new URL(window.location.href);
      Object.keys(defaultSettings).forEach((key) => {
        if (urlParams.searchParams.has(key)) {
          urlParams.searchParams.delete(key);
        }
      });
      Object.keys(payload).forEach((key) => {
        if (key === 'collapse') {
          return;
        }
        let value = payload[key];
        if (value === true) {
          value = 1;
        }
        if (defaultSettings[key] !== value) {
          urlParams.searchParams.set(key, value);
        }
      });
      const { primaryColor, colorWeak, contentWidth } = payload;
      if (primaryColor && state.primaryColor !== primaryColor) {
        updateTheme(primaryColor);
      }
      if (state.contentWidth !== contentWidth && window.dispatchEvent) {
        window.dispatchEvent(new Event('resize'));
      }
      updateColorWeak(!!colorWeak);
      window.history.replaceState(null, 'setting', urlParams.href);
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
