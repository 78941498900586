import { AxiosResponse } from 'axios';
import request from '@/utils/request';

export interface Score {
  /**
   * 课程名称
   */
  lesson_name: string;
  /**
    * 分数
    */
  score: number;
  /**
   * 学分
   */
  credit: number;
  /**
   * 课程类型
   * 必修 / 选修
   */
  lesson_type: string;
  /**
   * 考试类型
   */
  exam_type: string;
  /**
   * 课程类别
   * Ⅰ 类 / Ⅱ 类...
   */
  course_type: string;
}

export interface ScoreResponse {
  data: {
    score: {
      terms: string[]
      scores: Score[]
    }
  }
}

export const queryScore = async (term: string) => {
  const graphqlQueryContent = `
  {
    score(term: "${term}") {
      terms
      scores {
        lesson_name
        score
        credit
        lesson_type
        exam_type
        course_type
      }
    }
  }
  `;
  const res: AxiosResponse<ScoreResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: graphqlQueryContent,
    },
  );

  return res.data;
};
