import { Effect } from 'dva';
import { Reducer } from 'redux';
import { fetchUserInfo } from '@/services/user';
import { fetchStudentInfo } from '@/services/student_affairs/student_idcard';
import { AxiosResponse } from 'axios';

export interface IUserModelState {
  /** 姓名 */
  name: string;
  /** 学院 */
  department: string;
  /** 班级 */
  class: string;
  /** 头像链接 */
  avatar: string;
  /** 入学天数 */
  admittedDays: number;
  /** 学期周 */
  currentWeek: number;
  /** 访问次数 */
  accessTimes: number;
  /** 学号 */
  studentID: string,
  /** 手机号 */
  phone: string,
}

export interface IUserModelType {
  namespace: 'user';
  state: IUserModelState;
  effects: {
    setUserInfo: Effect;
  };
  reducers: {
    updateUserInfo: Reducer<IUserModelState>;
  };
}

const Model: IUserModelType = {
  namespace: 'user',

  state: {
    name: '',
    avatar: 'http://team.ncuos.com/favicon.ico',
    class: '',
    department: '',
    admittedDays: 0,
    currentWeek: 0,
    accessTimes: 0,
    studentID: '',
    phone: '',
  },

  effects: {
    * setUserInfo(_, { call, put }) {
      const infoResponse:AxiosResponse = yield call(fetchUserInfo);
      const stuInfoResponse: AxiosResponse = yield call(fetchStudentInfo);
      yield put({
        type: 'updateUserInfo',
        payload: { ...infoResponse.data.data.user, ...stuInfoResponse.data.data.mine },
      });
    },
  },

  reducers: {
    updateUserInfo(state, { payload }) {
      const {
        username,
        classname,
        college,
        enrollmentDays,
        termWeek,
        visitNumber,
        avatarUrl,
        studentID,
        phone,
      } = payload;
      return {
        ...state,
        name: username,
        class: classname,
        department: college,
        currentWeek: termWeek,
        admittedDays: enrollmentDays,
        accessTimes: visitNumber,
        avatar: avatarUrl,
        studentID,
        phone,
      };
    },
  },
};

export default Model;
