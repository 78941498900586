import { Reducer } from 'redux';
import { Effect } from 'dva';

import { cloneDeep } from 'lodash';
import { submit, getInfo, SparkInfo } from '@/services/student_affairs/spark';
import { AxiosResponse } from 'axios';
import { notification } from 'antd';

export interface ISparkApplyModelState {
  loading?: boolean;
  info?: SparkInfo;
  infoLoading?: boolean;
  isApply?: boolean;
}

export interface ISparkApplyModelType {
  namespace: string;
  state: ISparkApplyModelState;
  effects: {
    submit: Effect;
    getInfo: Effect;
  };
  reducers: {
    changeLoading: Reducer<ISparkApplyModelState>;
    changeInfo: Reducer<ISparkApplyModelState>;
    changeInfoLoading: Reducer<ISparkApplyModelState>;
    changeIsApply: Reducer<ISparkApplyModelState>;
  };
}

const Model: ISparkApplyModelType = {
  namespace: 'sparkApply',

  state: {
    loading: false,
    info: {} as SparkInfo,
    infoLoading: true,
    isApply: false,
  },

  effects: {
    * submit({ payload }, { call, put }) {
      yield put({
        type: 'changeLoading',
        payload: true,
      });
      const response: AxiosResponse<NcuosResponse> = yield call(submit, payload);
      notification.success({
        message: 'Success',
        description: response.data.message,
      });
      yield put({
        type: 'getInfo',
      });
    },

    * getInfo(_, { call, put }) {
      const response: AxiosResponse<NcuosResponse<SparkInfo>> = yield call(getInfo);
      const { data } = response.data;
      yield put({
        type: 'changeInfoLoading',
        payload: true,
      });
      yield put({
        type: 'changeInfo',
        payload: data,
      });
      yield put({
        type: 'changeIsApply',
        payload: data.is_apply,
      });
      yield put({
        type: 'changeInfoLoading',
        payload: false,
      });
    },
  },

  reducers: {
    changeLoading(state, { payload }) {
      return {
        ...state,
        infoLoading: payload,
      };
    },
    changeInfo(state, { payload }) {
      return {
        ...state,
        info: cloneDeep(payload),
      };
    },
    changeInfoLoading(state, { payload }) {
      return {
        ...state,
        infoLoading: payload,
      };
    },
    changeIsApply(state, { payload }) {
      return {
        ...state,
        isApply: payload,
      };
    },
  },
};

export default Model;
