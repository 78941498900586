import request from '@/utils/request';
import { AxiosResponse } from 'axios';

interface UserInfoResponse {
  data: {
    data: any
  };
}

// /** 姓名 */
// name: string;
// /** 学号 */
// studentID: string;
// /** 学院 */
// department: string;
// /** 性别 */
// gender: string;
// /** 入学时间 */
// admissionTime: string;
// /** 班级 */
// class: string;
// /** 身份证号 */
// idNumber: string;
// /** 血型 */
// bloodType: string;
// /** 生日 */
// birthday: string;
// /** 出生地 */
// birthplace: string;
// /** 头像链接 */
// avatar: string;
// /** 入学天数 */
// admittedDays: number;
// /** 学期周 */
// currentWeek: number;
// /** 访问次数 */
// accessTimes: number;

export async function fetchSchedule() {
  return request.get('https://v2.ncuos.com/api/query/curriculum/query');
}

export const fetchUserInfo = async () => {
  const queryContent = `
    {
        user {
            username
            classname
            college
            enrollmentDays
            termWeek
            visitNumber
            avatarUrl
        }
    }`;
  const response: AxiosResponse<UserInfoResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: queryContent,
    },
  );
  return response;
};
