import { message } from 'antd';
import { history } from 'umi';
import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import {
  fetchSubject,
  fetchCourse,
  fetchTest,
  SubjectResponse,
  CourseResponse,
  SubjectItem,
  CourseItem,
  TestResponse,
  TestItem,
  QueryInfoRes,
  queryInfo,
  SubmitResponse,
  submitAnswer,
} from '@/services/student_affairs/safety_education';

const getCourseMap = (courses: CourseItem[]) => {
  const record: Record<number, CourseItem[]> = {};
  courses.forEach((course) => {
    if (!record[course.subject_id]) record[course.subject_id] = [];
    record[course.subject_id].push(course);
  });
  return record;
};

export interface Item extends SubjectItem {
  courses: CourseItem[]
}

export interface ISafetyEducationModelState {
  info?: {
    xh: string
    currentTerm: string
  }
  data?: Item[]
  tests?: TestItem[]
}

export interface ISafetyEducationModelType {
  namespace: string
  state: ISafetyEducationModelState
  effects: {
    requestData: Effect
    requestTest: Effect
    requestInfo: Effect
    submitAnswer: Effect
  };
  reducers: {
    updateData: Reducer<ISafetyEducationModelState>
    updateTest: Reducer<ISafetyEducationModelState>
    updateInfo: Reducer<ISafetyEducationModelState>
  };
}

const Model: ISafetyEducationModelType = {
  namespace: 'safetyEducation',

  state: {
    data: [],
    tests: [],
  },

  effects: {
    * requestData(_, { call, put }) {
      const subjectRes: SubjectResponse = yield call(fetchSubject);
      const { data: { security: data } } = subjectRes;
      if (data?.length > 0) {
        const courseRes: CourseResponse = yield call(fetchCourse);
        const { data: { security: courseItems } } = courseRes;
        const courseMap = getCourseMap(courseItems);
        (data as Item[]).forEach((item) => {
          item.courses = courseMap[item.id];
        });
        yield put({
          type: 'updateData',
          payload: data,
        });
        yield put({
          type: 'requestInfo',
        });
      }
    },

    * requestTest({ payload }, { call, put }) {
      const res: TestResponse = yield call(fetchTest, Number(payload));
      const { data: { test } } = res;
      if (test?.length > 0) {
        yield put({
          type: 'updateTest',
          payload: test,
        });
      }
    },

    * requestInfo(_, { call, put }) {
      const res: QueryInfoRes = yield call(queryInfo);
      const {
        data:
        {
          mine: { studentID },
          termStatus: { currentTerm },
        },
      } = res;
      yield put({
        type: 'updateInfo',
        payload: {
          xh: studentID,
          currentTerm,
        },
      });
    },

    * submitAnswer({ payload }, { call }) {
      const res: SubmitResponse = yield call(submitAnswer, payload);
      if (res.status !== 0) {
        message.error(res.message);
      } else {
        message.success(res.message);
        history.goBack();
      }
    },
  },

  reducers: {
    updateData(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
    updateTest(state, { payload }) {
      return {
        ...state,
        tests: cloneDeep(payload),
      };
    },
    updateInfo(state, { payload }) {
      return {
        ...state,
        info: cloneDeep(payload),
      };
    },
  },
};

export default Model;
