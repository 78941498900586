import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import { queryScore, Score, ScoreResponse } from '@/services/usually_query/grades_query';

export type IGradesQueryModelState = {
  scores: Score[],
  terms: string[]
};

export interface IGradesQueryModelType {
  namespace: string;
  state: IGradesQueryModelState;
  effects: {
    requestScores: Effect;
    requestTerms: Effect;
  };
  reducers: {
    updateGradesQuery: Reducer<IGradesQueryModelState>;
  };
}

const Model: IGradesQueryModelType = {
  namespace: 'gradesQuery',

  state: {
    terms: [],
    scores: [],
  },

  effects: {
    * requestTerms(_, { call, put }) {
      const termsRes: ScoreResponse = yield call(queryScore, '');
      const {
        data: {
          score: {
            terms,
          },
        },
      } = termsRes;
      if (terms?.length <= 0) return;
      yield put({
        type: 'updateGradesQuery',
        payload: {
          terms,
        },
      });
    },
    * requestScores({ payload }, { call, put }) {
      const scoreRes: ScoreResponse = yield call(queryScore, payload);
      const {
        data: {
          score: {
            scores,
          },
        },
      } = scoreRes;
      if (scores?.length <= 0) return;
      yield put({
        type: 'updateGradesQuery',
        payload: {
          scores,
        },
      });
    },
  },

  reducers: {
    updateGradesQuery(state, { payload }) {
      return {
        ...state,
        ...cloneDeep(payload),
      };
    },
  },
};

export default Model;
