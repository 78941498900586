import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';

import { fetchNotice, NoticeItem, NoticeResponse } from '@/services/notice';

export interface INoticeListModelState {
  data: IData;
}

interface IData {
  total: number;
  notice: NoticeItem[];
}

export interface INoticeListModelType {
  namespace: string;
  state: INoticeListModelState;
  effects: {
    getNoticeList: Effect;
  };
  reducers: {
    updateNoticeList: Reducer<INoticeListModelState>;
  };
}

const Model: INoticeListModelType = {
  namespace: 'notice',

  state: {
    data: {
      total: 0,
      notice: [],
    },
  },

  effects: {
    * getNoticeList(_, { call, put }) {
      const response: NoticeResponse = yield call(fetchNotice);
      const { data } = response;
      if (data?.length > 0) {
        yield put({
          type: 'updateNoticeList',
          payload: data,
        });
      }
    },
  },

  reducers: {
    updateNoticeList(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
  },
};

export default Model;
