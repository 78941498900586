import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import {
  queryCurrentTerm,
  queryData,
  QueryResponse,
  ClassMember,
  CurrentStatus,
  CurrentTermResponse,
} from '@/services/usually_query/class_contacts';

export interface IClassContactsModelState {
  classMembers: ClassMember[];
  currentStatus: CurrentStatus;
}

export interface IClassContactsModelType {
  namespace: string;
  state: IClassContactsModelState;
  effects: {
    requestData: Effect;
  };
  reducers: {
    updateClassContacts: Reducer<IClassContactsModelState>;
  };
}

const Model: IClassContactsModelType = {
  namespace: 'classContacts',

  state: {
    classMembers: [],
    currentStatus: {
      className: '',
      major: '',
      department: '',
    },
  },

  effects: {
    * requestData(_, { call, put }) {
      const currentTermRes: CurrentTermResponse = yield call(queryCurrentTerm);
      const { data: { termStatus: { currentTerm } } } = currentTermRes;
      if (!currentTerm) return;
      const response: QueryResponse = yield call(queryData, currentTerm);
      const {
        data: {
          mine: {
            classMembers,
            currentStatus,
          },
        },
      } = response;
      if (currentStatus?.className) {
        yield put({
          type: 'updateClassContacts',
          payload: {
            classMembers,
            currentStatus,
          },
        });
      }
    },
  },

  reducers: {
    updateClassContacts(state, { payload }) {
      const { classMembers, currentStatus } = payload;
      return {
        ...state,
        classMembers: cloneDeep(classMembers),
        currentStatus: cloneDeep(currentStatus),
      };
    },
  },
};

export default Model;
