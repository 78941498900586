import { parse } from 'querystring';
import _ from 'lodash';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const hidePhone = (p = '') => (p ? `${p?.substr(0, 3)}****${p?.substr(7, 11)}` : '');

export const cutText = (text: string, limit: number) => (text.length > limit ? `${text.substr(0, limit)}...` : text);

export const parseLessonTime = (time: string) => {
  let courses = (time.substr(1) as any)
    .match(/.{1,2}/g) // 030405 -> 03, 04, 05
    .map((x: string) => Number(x));

  // 适配首尾区间情况
  if (courses.length === 2 && Number(courses[0]) + 1 < Number(courses[1])) {
    courses = _.range(courses[0], Number(courses[1]) + 1);
  }
  return courses;
};

export interface ILesson {
  _id?: string;
  key: string;
  name: string;
  teacher: string;
  time: string;
  classroom: string;
  week: string;
  type: number;
  count: number;
  priority?: number;
  valid?: boolean;
  range?: number[];
  bgColor?: string;
  textColor?: string;
}
