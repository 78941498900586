import { Reducer } from 'redux';
import { Effect } from 'dva';
import { fetchSchedule } from '@/services/schedule';
import { parseLessonTime, ILesson } from '../utils/utils';
import { timeMap } from '../components/ClassSchedule/utils';

export interface IScheduleModelState {
  data: Item[];
}

interface Item {
  key?: string;
  date: number;
  start: number;
  end: number;
  classroom: string;
  name: string;
  teacher: string;
  count: number
  week: string
  time: string
}

export interface IScheduleModelType {
  namespace: string;
  state: IScheduleModelState;
  effects: {
    requestSchedule: Effect;
  };
  reducers: {
    updateSchedule: Reducer<IScheduleModelState>;
  };
}

const Model: IScheduleModelType = {
  namespace: 'schedule',

  state: {
    data: [],
  },

  effects: {
    * requestSchedule(_, { call, put }) {
      const response = yield call(fetchSchedule);
      yield put({
        type: 'updateSchedule',
        payload: response,
      });
    },
  },

  reducers: {
    updateSchedule(state, { payload }) {
      const lessons = payload.data.data.lessons.map((item: ILesson) => {
        const startSec = parseLessonTime(item.time)[0];
        const endSec = parseLessonTime(item.time)[1];
        const startTime = timeMap[startSec - 1].time;
        const endTime = timeMap[endSec - 1].time;
        const time = `${startTime}-${endTime}`;

        return {
          key: item.key,
          date: Number(item.time[0]),
          start: startSec,
          end: endSec,
          classroom: item.classroom,
          name: item.name,
          teacher: item.teacher,
          week: item.week,
          count: item.count,
          time,
        };
      });
      return {
        ...state,
        data: lessons,
      };
    },
  },
};

export default Model;
