import request from '@/utils/request';
import { AxiosResponse } from 'axios';

export interface SparkInfo {
  /** 申请状态 */
  'apply_status': number;
  /** 新生 */
  'freshman': {
    /** 班级 */
    'class': string;
    'id': number;
    /** 星火使者学号 */
    'xhsz_xh': string;
    /** 新生学号 */
    'xs_xh': string;
    /** 评价 */
    'evaluation': string;
    /** 满意度 */
    'satisfaction': 0,
    /** 学期 */
    'term': string;
  },
  /** 是否已经申请 */
  'is_apply': true,
  /** 社团名称 */
  'remark': '',
  /** 老生 */
  'student': {
    /** 学号 */
    'xh': string;
    /** 姓名 */
    'xm': string;
    /** 性别 */
    'xb': string,
    /** 政治面貌 */
    'zzmm': string,
    /** 电话 */
    'yddh': string,
    /** QQ */
    'qq': string
  }
}

export async function submit(remark: string) {
  const response: AxiosResponse<NcuosResponse> = await request.post('https://v2.ncuos.com/api/affair/spark/apply', { remark });
  return response;
}

export async function getInfo() {
  const response: AxiosResponse<NcuosResponse<SparkInfo>> = await request.get('https://v2.ncuos.com/api/affair/spark/info');
  return response;
}
