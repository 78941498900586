import { AxiosResponse } from 'axios';
import request from '@/utils/request';

const PREFIX = 'https://v2.ncuos.com/api/affair/photo';

export type UpdatePhotoResponse = NcuosResponse<Record<any, any>>;

export const updatePhoto = async (base64data: string) => {
  const res: AxiosResponse<UpdatePhotoResponse> = await request
    .post(`${PREFIX}/new`, { encoded_pic: base64data });
  return res.data;
};

export interface RecordsResponse extends NcuosResponse {
  data: {
    pic: string,
    ischeck: boolean
    ispass: boolean
    time: number
  }
}

export const fetchRecords = async () => {
  const res: AxiosResponse<RecordsResponse> = await request.get(`${PREFIX}/records`);
  return res.data;
};
