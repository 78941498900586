import request from '@/utils/request';
import { AxiosResponse } from 'axios';

export interface FeedbackDto {
  type: string;
  title: string;
  content: string;
}

export interface FetchAcceptanceStatusRes {
  id: number;
  title: string;
  submit_time: string;
  mc: string;
  content: string;
  reply: string;
  isreply: boolean;
}

export interface FetchFeedbackTypesRes {
  DM: string;
  MC: string;
}

export interface FrequentFeedbacksRes {
  id: number;
  submit_time: string;
  title: string;
  mc: string;
  content: string;
  reply: string;
}

export type FeedbackResponse = NcuosResponse<FetchFeedbackTypesRes[]>;
export type AcceptanceStatusResponse = NcuosResponse<FetchAcceptanceStatusRes[]>;
export type FrequentFeedbacksRespose = NcuosResponse<FrequentFeedbacksRes[]>;

export async function submitFeedback(data: FeedbackDto) {
  const response: AxiosResponse<NcuosResponse> = await request.post(
    'https://v2.ncuos.com/api/account/feedback/send',
    {
      dm: data.type,
      title: data.title,
      content: data.content,
    },
  );
  return response;
}

export async function fetchAcceptanceStatus() {
  const response: AxiosResponse<AcceptanceStatusResponse> = await request.get('https://v2.ncuos.com/api/account/feedback/query');
  return response.data;
}

export async function fetchFeedbackTypes() {
  const response: AxiosResponse<FeedbackResponse> = await request.get('https://v2.ncuos.com/api/account/feedback/name');
  return response.data;
}

export async function fetchFrequentFeedbacks() {
  const response: AxiosResponse<FrequentFeedbacksRespose[]> = await request.get('https://v2.ncuos.com/api/account/feedback/frequent');
  return response.data;
}

export async function deleteFeedback(id: number) {
  const response: AxiosResponse = await request({
    method: 'DELETE',
    url: 'https://v2.ncuos.com/api/account/feedback/delete',
    data: { id },
  });
  return response;
}
