import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import { query, QueryItem, QueryResponse } from '@/services/usually_query/discipline_punish';

export interface IDisciplinePunishModelState {
  data: QueryItem[];
}

export interface IDisciplinePunishModelType {
  namespace: string;
  state: IDisciplinePunishModelState;
  effects: {
    requestData: Effect;
  };
  reducers: {
    updateDisciplinePunish: Reducer<IDisciplinePunishModelState>;
  };
}

const Model: IDisciplinePunishModelType = {
  namespace: 'disciplinePunish',

  state: {
    data: [],
  },

  effects: {
    * requestData(_, { call, put }) {
      const response = yield call(query);
      const {
        data,
      } = response as QueryResponse;
      if (data?.length > 0) {
        yield put({
          type: 'updateDisciplinePunish',
          payload: data,
        });
      }
    },
  },

  reducers: {
    updateDisciplinePunish(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
  },
};

export default Model;
