import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import {
  fetchProofInfo,
  ProofInfoResponse,
  ProofInfoData,
  fetchStudentId,
  StudentIdResponse,
} from '@/services/student_affairs/print_proof';
import { fetchPhoto, PhotoResponse } from '@/services/qiniu';

export interface IProofModelState {
  data?: ProofInfoData;
  img?: string
}

export interface IProofModelType {
  namespace: string;
  state: IProofModelState;
  effects: {
    requestProof: Effect;
    requestPhoto: Effect;
  }
  reducers: {
    getProof: Reducer<IProofModelState>;
    getPhoto: Reducer<IProofModelState>;
  }
}

const ProofModel: IProofModelType = {
  namespace: 'proof',

  state: {},

  effects: {
    * requestProof(_, { call, put }) {
      const response: ProofInfoResponse = yield call(fetchProofInfo);
      const { data } = response;
      yield put({
        type: 'getProof',
        payload: data,
      });
      yield put({
        type: 'requestPhoto',
      });
    },
    * requestPhoto(_, { call, put }) {
      try {
        const idRes: StudentIdResponse = yield call(fetchStudentId);
        const { data: { mine: { studentID } } } = idRes;
        if (studentID) {
          const photoRes: PhotoResponse = yield call(fetchPhoto, studentID);
          const { data } = photoRes;
          if (data?.length > 0) {
            yield put({
              type: 'getPhoto',
              payload: data,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  reducers: {
    getProof(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
    getPhoto(state, { payload }) {
      return {
        ...state,
        img: payload,
      };
    },
  },
};

export default ProofModel;
