import { Reducer } from 'redux';
import { Effect } from 'dva';
import dayjs from 'dayjs';

import { fetchRegisteHistories, RegisteHistoriesRes, registe } from '@/services/student_affairs/vacation_register';
import { AxiosResponse } from 'axios';
import { message } from 'antd';

interface RegisteHistories {
  term: string;
  /* 登记类型 0离校 1返校 */
  type: number;
  location: string;
  date: string;
}

export interface IVacationRegisterState {
  term?: string;
  location?: string;
  date?: string;
  /* 登记类型 0离校 1返校 */
  type?: number;
  registeHistories?: RegisteHistories[];
}

export interface IVacationRegisterModelType {
  namespace: string;
  state: IVacationRegisterState;
  effects: {
    fetchRegisterInfo: Effect;
    vacationRegiste: Effect;
  };
  reducers: {
    updateRegisterInfo: Reducer<IVacationRegisterState>;
  };
}

const Model: IVacationRegisterModelType = {
  namespace: 'vacationRegister',

  state: {
    term: '',
    location: '',
    date: dayjs().format('YYYY年 MM月 DD日'),
  },

  effects: {
    * fetchRegisterInfo(_, { call, put }) {
      const { data }: AxiosResponse<NcuosResponse<RegisteHistoriesRes>> = yield call(fetchRegisteHistories);
      console.log(data.data);
      const { term } = data.data;
      const registeHistories: RegisteHistories[] = data.data.records.map((registeHistory) => {
        const {
          Semester, Location, LocationType, Time,
        } = registeHistory;
        return {
          term: Semester,
          type: Number(LocationType),
          location: Location,
          date: Time,
        };
      });

      yield put({
        type: 'updateRegisterInfo',
        payload: {
          term,
          registeHistories,
        },
      });
    },

    * vacationRegiste({ payload }, { call }) {
      const { data }: AxiosResponse<NcuosResponse<string>> = yield call(registe, payload);
      message.success(data.message);
    },
  },

  reducers: {
    updateRegisterInfo: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },

};

export default Model;
