import request from '@/utils/request';

export interface QueryItem {
  /**
   * xh 学号
   */
  xh: string;
  /**
   * 姓名
   */
  xm: string;
  /**
   * 处分名称
   */
  cfmc: string;
  /**
   * 处分原因
   */
  cfyy: string;
  /**
   * 处分日期
   */
  cfrq: string;
  /**
   * 处分文号
   */
  cfwh: string;
  /**
   * 处分撤销日期
   */
  cfcrq: string;
  /**
   * 处分撤销文号
   */
  cfcwh: string;
}

export interface QueryResponse {
  data: QueryItem[]
  message: string;
  status: 0;
}

export async function query() {
  const res: QueryResponse = await request.get('https://v2.ncuos.com/api/query/discipline/query');
  return res;
}
