/* eslint-disable @typescript-eslint/naming-convention */
import { Reducer } from 'redux';
import { Effect } from 'dva';
import dayjs from 'dayjs';

import {
  submitIdCardChangeRequest, fetchIdCardChangeHisory, fetchStudentInfo,
} from '@/services/student_affairs/student_idcard';
import { AxiosResponse } from 'axios';
import { notification } from 'antd';

interface HistoryItem {
  id: number,
  term: string,
  student_id: number,
  student_name: string,
  college: string,
  clazz: string,
  apply_reason: string,
  apply_time: string,
  apply_status: false,
  check_user: string,
  check_time: string,
}

interface userData {
  studentID?: string,
  name?: string,
  gender?: string,
  admissionTime?: string,
  idNumber?: string,
  birthplace?: string,
  nativePlace?: string,
  ethnicGroup?: string,
  phone?: string,
  currentStatus?: {
    major?: string,
    department?: string
  }
}

export interface IStudentIdcardModelState {
  loading?: boolean;
  historyData?: HistoryItem[];
  userData?: userData;
}

export interface IStudentIdcardModelType {
  namespace: string;
  state: IStudentIdcardModelState;
  effects: {
    submitApplication: Effect;
    requestHistory: Effect;
    requestStudentInfo: Effect;
  };
  reducers: {
    updateLoading: Reducer<IStudentIdcardModelState>;
    updateHistory: Reducer<IStudentIdcardModelState>;
    updateStudentInfo: Reducer<IStudentIdcardModelState>;
  };
}

const Model: IStudentIdcardModelType = {
  namespace: 'studentIdcard',

  state: {
    loading: false,
    historyData: [],
    userData: {},
  },

  effects: {
    * submitApplication({ payload }, { call, put }) {
      yield put({
        type: 'updateLoading',
        payload: true,
      });
      const response: AxiosResponse<NcuosResponse> = yield call(submitIdCardChangeRequest, payload);
      yield put({
        type: 'updateLoading',
        payload: false,
      });
      notification.success({
        message: 'Success',
        description: response.data.message,
      });
    },
    * requestHistory(_, { call, put }) {
      const response: AxiosResponse<NcuosResponse> = yield call(fetchIdCardChangeHisory);
      yield put({
        type: 'updateHistory',
        payload: response.data.data,
      });
    },
    * requestStudentInfo(_, { call, put }) {
      yield put({
        type: 'updateLoading',
        payload: true,
      });
      const response: AxiosResponse<NcuosResponse> = yield call(fetchStudentInfo);
      yield put({
        type: 'updateStudentInfo',
        payload: response.data.data.mine,
      });
      yield put({
        type: 'updateLoading',
        payload: false,
      });
    },
  },

  reducers: {
    updateLoading(state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
    updateStudentInfo(state, { payload }) {
      return {
        ...state,
        userData: payload,
      };
    },
    updateHistory(state, { payload }) {
      const historyData = payload?.map((item: any) => {
        let applyTime = item.apply_time;
        let checkTime = item.check_user.Time;
        applyTime = dayjs(applyTime).format('YYYY/MM/DD HH:mm');
        checkTime = dayjs(checkTime).format('YYYY/MM/DD HH:mm');
        const statusMap = ['待办理', '办理中', '已制作', '已签收', '已退回'];
        const apply_time = applyTime;
        const check_user = item.check_user.Valid === true ? item.check_user.String : '-';
        const check_time = item.check_time.Valid === true ? checkTime : '-';
        const apply_status = statusMap[item.apply_status];
        const key = item.id;
        return {
          ...item, check_user, check_time, apply_status, key, apply_time,
        };
      });

      return {
        ...state,
        historyData,
      };
    },
  },
};

export default Model;
