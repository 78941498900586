import { Reducer } from 'redux';
import { Effect } from 'dva';
import {
  fetchFeedbackTypes,
  FeedbackResponse,
  fetchAcceptanceStatus,
  FetchAcceptanceStatusRes,
  fetchFrequentFeedbacks,
  AcceptanceStatusResponse,
  FrequentFeedbacksRes,
  FrequentFeedbacksRespose,
} from '@/services/feedback';
import { cloneDeep } from 'lodash';

export interface FeedbackTypesType {
  value: string;
  name: string;
}

export interface FetchFeedbackStatusData {
  id: number;
  title: string;
  submit_time: string;
  mc: string;
  content: string;
  reply: string;
  isreply: boolean;
}

export interface IFeedbackModelState {
  feedbackTypes?: FeedbackTypesType[];
  acceptanceStatus?: FetchFeedbackStatusData[];
  frequentFeedbacks?: FrequentFeedbacksRes[];
}

export interface IFeedbackModelType {
  namespace: string;
  state: IFeedbackModelState;
  effects: {
    setInfo: Effect;
  };
  reducers: {
    setFeedbackTypes: Reducer<IFeedbackModelState>;
    setAcceptanceStatus: Reducer<IFeedbackModelState>;
    setFrequentFeedbaks: Reducer<IFeedbackModelState>
  };
}

const Model: IFeedbackModelType = {
  namespace: 'feedback',

  state: {
    feedbackTypes: [],
    acceptanceStatus: [],
  },

  effects: {
    * setInfo(_, { call, put }) {
      const fetchFeedbackTypeResponse: FeedbackResponse = yield call(fetchFeedbackTypes);
      const fetchFeedbackTypeData: FeedbackTypesType[] = fetchFeedbackTypeResponse.data.map((type): FeedbackTypesType => ({ value: type.DM, name: type.MC }));
      yield put({
        type: 'setFeedbackTypes',
        payload: fetchFeedbackTypeData,
      });

      const fetchAcceptanceStatusResponse: AcceptanceStatusResponse = yield call(fetchAcceptanceStatus);
      const fetchAcceptanceStatusData: FetchAcceptanceStatusRes[] = fetchAcceptanceStatusResponse.data;
      yield put({
        type: 'setAcceptanceStatus',
        payload: fetchAcceptanceStatusData,
      });

      const fetchFrequentFeedbacksResponse: FrequentFeedbacksRespose = yield call(fetchFrequentFeedbacks);
      const frequentFeedbacks = fetchFrequentFeedbacksResponse.data;
      yield put({
        type: 'setFrequentFeedbaks',
        payload: frequentFeedbacks,
      });
    },
  },

  reducers: {
    setFeedbackTypes(state, { payload }) {
      return {
        ...state,
        feedbackTypes: cloneDeep(payload),
      };
    },
    setAcceptanceStatus(state, { payload }) {
      return {
        ...state,
        acceptanceStatus: cloneDeep(payload),
      };
    },
    setFrequentFeedbaks(state, { payload }) {
      return {
        ...state,
        frequentFeedbacks: cloneDeep(payload),
      };
    },
  },
};

export default Model;
