import { message, notification } from 'antd';
import { history } from 'umi';
import axios from 'axios';
import prudoce from 'immer';

const request = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  timeout: 10000,
});

const getBearerToken = () => {
  const token = window.localStorage.getItem('token');
  if (token) {
    return `Bearer ${token}`;
  }
  return undefined;
};

request.interceptors.request.use((config) => {
  const token = getBearerToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, (error) => {
  notification.error({
    message: 'Error',
    description: error,
  });
  return Promise.reject(error);
});

// Add a response interceptor
request.interceptors.response.use((res) => res, (error) => {
  // TODO: 添加token自动续期的功能
  if (error.response.status === 401) {
    message.warning('登录状态已过期, 请重新登陆');
    localStorage.removeItem('token');
    history.push('/');
  }
  notification.error({
    message: 'Error',
    description: String(error),
  });
  return Promise.reject(error);
});

export default request;
