import { AxiosResponse } from 'axios';
import request from '@/utils/request';

export interface ProofInfoData {
  /** 姓名 */
  xm: string
  /** 出生日期 */
  csrq: string
  /** 性别 */
  xb: string
  /** 民族 */
  mz: string
  /** 身份证号 */
  sfzh: string
  /** 入学年份 */
  rxny: string
  /** 院系所名称 */
  yxsmc: string
  /** 班级名称 */
  bjmc: string
}
export interface ProofInfoResponse extends NcuosResponse {
  data: ProofInfoData
}

export async function fetchProofInfo() {
  const res: AxiosResponse<ProofInfoResponse> = await request.get('https://v2.ncuos.com/api/affair/proof');
  return res.data;
}

export interface StudentIdResponse extends NcuosResponse {
  data: {
    mine: {
      studentID: string
    }
  }
}

export const fetchStudentId = async () => {
  const queryContent = `
  {
    mine {
        studentID
    }
  }`;
  const response: AxiosResponse<StudentIdResponse> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: queryContent,
    },
  );
  return response.data;
};
