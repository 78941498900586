import request from '@/utils/request';
import { AxiosResponse } from 'axios';

interface LoginHistoryParamsType{
  size?: number;
  cursor?: string;
  nextPage?: boolean;
}

export const logout = async (): Promise<AxiosResponse> => request.delete('https://v2.ncuos.com/api/account/user/logout');

export const fetchPhoneVerifyCode = async (): Promise<AxiosResponse> => request.get('https://v2.ncuos.com/api/account/user/verify_code');

export const fetchAccessLogs = async (params: LoginHistoryParamsType = {}): Promise<AxiosResponse> => {
  const { size = 100000, cursor = '', nextPage = false } = params;
  const queryContent = `
  {
    AccessLogs(size:${size}, cursor:"${cursor}", nextPage:${String(nextPage)}){
        accessLogs{
            ipAddress
            deviceType
            accessTime
        }
        listMetadata{
            count
            page
            cursor
            endCursor
            hasNext
        }
    }
  }`;
  const response: AxiosResponse = await request.post(
    'https://info-center.ncuos.com/graphql', { query: queryContent },
  );
  return response;
};

export const changePassword = async (oldPwd = '', newPwd = ''): Promise<AxiosResponse> => request.put('https://v2.ncuos.com/api/account/user/change/password', { old: oldPwd, new: newPwd });

export const changePhone = async (verifyCode = 0, newPhone = ''):Promise<AxiosResponse> => request.post('https://v2.ncuos.com/api/account/user/phone', { verify_code: verifyCode, new_phone: newPhone });
