import { AxiosResponse } from 'axios';
import request from '@/utils/request';

const PREFIX = 'https://v2.ncuos.com/api/account/security';

/**
 * 安全教育各接口关系
 * fetchInfo 获取用户答题情况
 * fetchSubject -> fetchCourse     -> fetchTest      -> submitAnswer
 * 大栏目        -> 栏目里的课程/话题  -> 课程对应的测试   -> 提交答案
 * 如：
 * 三月份课程     -> 拒绝盲目，理性恋爱 -> Q & A
 */

export interface SubjectItem {
  id: number
  name: string
  term_id: string
}

export interface SubjectResponse extends NcuosResponse {
  data: {
    security: SubjectItem[]
  }
}

export async function fetchSubject() {
  const res: AxiosResponse<SubjectResponse> = await request.get(`${PREFIX}/subject`);
  return res.data;
}

export interface CourseItem {
  id: number
  subject_id: number
  name: string
  sort: number
  url: string
}

export interface CourseResponse extends NcuosResponse {
  data: {
    security: CourseItem[]
  }
}

export async function fetchCourse() {
  const res: AxiosResponse<CourseResponse> = await request.get(`${PREFIX}/setting`);
  return res.data;
}

export interface TestItem {
  id: number
  course_id: number
  title: string
  a: string
  b: string
  c: string
  d: string
  sort: number
  answer: string
}
export interface TestResponse extends NcuosResponse {
  data: {
    test: TestItem[]
  }
}

export async function fetchTest(courseId: number) {
  const res: AxiosResponse<TestResponse> = await request.post(`${PREFIX}/test/info`, {
    id: courseId,
  });
  return res.data;
}

export interface SubmitBody {
  xh: string
  course_id: number
  answers: Array<{
    id: number,
    choice: string
  }>
}
export interface SubmitResponse extends NcuosResponse {
  data: Record<any, any>
}

export async function submitAnswer(body: SubmitBody) {
  const res: AxiosResponse<SubmitResponse> = await request.post(`${PREFIX}/user`, body);
  return res.data;
}

// TODO: 与 models 合并，避免重复查询 info-center
export interface QueryInfoRes {
  data: {
    mine: {
      studentID: string
    }
    termStatus: {
      currentTerm: string
    }
  }
}

export async function queryInfo() {
  const queryContent = `
    {
      mine {
        studentID
      }
      termStatus {
        currentTerm
      }
    }
  `;
  const res: AxiosResponse<QueryInfoRes> = await request.post(
    'https://info-center.ncuos.com/graphql',
    {
      query: queryContent,
    },
  );
  return res.data;
}

export interface InfoData {
  id: string;
  is_finished: boolean
}

export interface InfoResponse extends NcuosResponse {
  data: {
    security: InfoData
  }
}

export async function fetchInfo() {
  const {
    data:
    {
      mine: { studentID },
      termStatus: { currentTerm },
    },
  } = await queryInfo();
  const res: AxiosResponse<InfoResponse> = await request.post(`${PREFIX}/user/info`, {
    xh: studentID,
    term: currentTerm,
  });
  return res.data;
}
