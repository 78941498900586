import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import { fetchExcellentStudents, fetchTerms } from '@/services/student_affairs/excellent_students';

export interface IExcellentStudentsModelState {
  data?: ExcellentStudentState[];
  terms?: string[];
}

export interface ExcellentStudentState {
  key?: string;
  index?: string;
  name?: string;
  studentId?: string;
  scholarship?: string;
  studentLeader?: string;
  result?: string;
  reward?: string;
}

export interface IExcellentStudentsModelType {
  namespace: string;
  state: IExcellentStudentsModelState;
  effects: {
    requestTerms: Effect;
    requestExcellentStudents: Effect;
  };
  reducers: {
    updateExcellentStudents: Reducer<IExcellentStudentsModelState>;
    updateTerms: Reducer<IExcellentStudentsModelState>;
  };
}

const Model: IExcellentStudentsModelType = {
  namespace: 'excellentStudents',

  state: {
    data: [],
    terms: [],
  },

  effects: {
    * requestTerms(_, { call, put }) {
      const response = yield call(fetchTerms);
      if (response.status === 200) {
        yield put({
          type: 'updateTerms',
          payload: response.data.data,
        });
      }
    },
    * requestExcellentStudents({ payload }, { call, put }) {
      const response = yield call(fetchExcellentStudents, payload);
      if (response.status === 200) {
        const data: ExcellentStudentState[] = response.data.data.map((item: any, index: number) => ({
          name: item.xsxm,
          studentId: item.xsxh,
          scholarship: item.jxj,
          studentLeader: item.sfxsgb === 1 ? '是' : '否',
          reward: item.sfhj === 1 ? '获奖' : '未获奖',
          result: item.pdjg === 1 ? '通过' : '未通过',
          key: index + 1,
        }));
        yield put({
          type: 'updateExcellentStudents',
          payload: data,
        });
      }
    },
  },

  reducers: {
    updateExcellentStudents(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
    updateTerms(state, { payload }) {
      return {
        ...state,
        terms: cloneDeep(payload),
      };
    },
  },
};

export default Model;
