import { AxiosResponse } from 'axios';
import request from '@/utils/request';

export interface PhotoResponse extends NcuosResponse {
  data: string
}

export async function fetchPhoto(studentId: string) {
  const filename = `${studentId}.jpg`;
  const res: AxiosResponse<PhotoResponse> = await request
    .post('https://v2.ncuos.com/api/affair/photo/qiniu', {
      name: filename,
    });
  return res.data;
}
