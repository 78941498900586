import { AxiosResponse } from 'axios';
import request from '@/utils/request';

export interface NoticeItem {
  id: number
  title: string
  content: string
  public_user_id: number
}
export interface NoticeResponse extends NcuosResponse {
  data: NoticeItem[]
}

export async function fetchNotice() {
  const res: AxiosResponse<NoticeResponse> = await request.get('https://v2.ncuos.com/api/account/notice/get');
  return res.data;
}
