import { Reducer } from 'redux';
import { history } from 'umi';
import { Effect } from 'dva';

import { login, LoginRes } from '@/services/login';
import { getPageQuery } from '@/utils/utils';

export interface ILoginModelState {
  loading?: boolean;
  type?: string;
}

export interface ILoginModelType {
  namespace: string;
  state: ILoginModelState;
  effects: {
    login: Effect;
    logout: Effect;
  };
  reducers: {
    changeLoading: Reducer<ILoginModelState>;
  };
}

const Model: ILoginModelType = {
  namespace: 'login',

  state: {
    loading: false,
    type: undefined,
  },

  effects: {
    * login({ payload }, { call, put }) {
      yield put({
        type: 'changeLoading',
        payload: true,
      });
      const response: LoginRes = yield call(login, payload);
      yield put({
        type: 'changeLoading',
        payload: false,
      });
      // Login successfully
      if (response.data.token.length > 0) {
        window.localStorage.setItem('token', response.data.token);
        yield history.push({
          pathname: '/dashboard',
        });
      }
    },

    * logout() {
      const { redirect } = getPageQuery();
      // redirect
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield history.push({
          pathname: '/dashboard',
        });
      }
    },
  },

  reducers: {
    changeLoading(state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },
  },
};

export default Model;
