import request from '@/utils/request';
import { AxiosResponse } from 'axios';

export interface RegisteHistoriesRes {
  records: {
    Semester: string;
    /* 登记类型 0离校 1返校 */
    LocationType: string;
    Location: string;
    Time: string;
  }[];
  term: string;
}

interface RegisteDto {
  /* 登记类型 0离校 1返校 */
  location_type: 0 | 1;
  location: string;
}

export const fetchRegisteHistories = async (): Promise<AxiosResponse<NcuosResponse<RegisteHistoriesRes[]>>> => request.get('https://v2.ncuos.com//api/affair/location');

export const registe = async (data: RegisteDto): Promise<AxiosResponse<NcuosResponse<string>>> => request.post('https://v2.ncuos.com/api/affair/location', { ...data });
