import { Reducer } from 'redux';
import { Effect } from 'dva';
import { cloneDeep } from 'lodash';
import { fetchQuality, fetchTerms, TermsRes } from '@/services/student_affairs/comprehensive_quality';
import { AxiosResponse } from 'axios';

export interface IQualityModelState {
  data?: Data[];
  terms?: string[];
}

export interface Data {
  key: string;
  name: string;
  studentId: string;
  basicTotal: string;
  basicReason: string;
  basicScore: string;
  grade: string;
  rank: string;
  innovationTotal: string;
  innovationReason: string;
  innovationScore: string;
  result: string;
  scholarship: string;
}

export interface IQualityModelType {
  namespace: string;
  state: IQualityModelState;
  effects: {
    requestQuality: Effect;
    requestTerms: Effect;
  };
  reducers: {
    updateQuality: Reducer<IQualityModelState>;
    updateTerms: Reducer<IQualityModelState>;
  };
}

const Model: IQualityModelType = {
  namespace: 'quality',

  state: {
    data: [],
    terms: [],
  },

  effects: {
    * requestQuality({ payload }, { call, put }) {
      const response = yield call(fetchQuality, payload);
      const resData = response.data.data;
      const data: Data[] = response.data.status === 1
        ? []
        : [{
          key: '0',
          name: resData.name,
          studentId: resData.student_id,
          basicTotal: resData.basic_quality.total,
          basicReason: '长得太帅',
          basicScore: resData.basic_quality.items[0].score,
          grade: resData.grade,
          rank: resData.rank,
          innovationReason: '腿长',
          innovationScore: resData.innovation_practice.items[0].score,
          innovationTotal: resData.innovation_practice.total,
          result: resData.result,
          scholarship: resData.scholarship,
        }];
      yield put({
        type: 'updateQuality',
        payload: data,
      });
    },
    * requestTerms(_, { call, put }) {
      const res: AxiosResponse<TermsRes> = yield call(fetchTerms);
      yield put({
        type: 'updateTerms',
        payload: res.data.data,
      });
    },
  },

  reducers: {
    updateQuality(state, { payload }) {
      return {
        ...state,
        data: cloneDeep(payload),
      };
    },
    updateTerms: (state, { payload }) => ({
      ...state,
      terms: cloneDeep(payload),
    }),
  },
};

export default Model;
